import classNames from 'classnames'
import React from 'react'
import Image from 'next/image'
import ButtonBlock from 'components/shared/ButtonBlock'
import {TextBlock} from './Text'
import {getImageResolution} from 'util/getImageResolution'
import {CampaignBannerStoryblok} from 'types/storyblok-types'
import BackgroundMapper from 'util/BackgroundMapper'
import {renderRichText, storyblokEditable} from '@storyblok/react'
import linkTypeChecker from 'util/linkTypeChecker'
import Link from 'next/link'

type CampaignBannerProps = {
  title: string
  text: string
  image_url?: string
  image_alt?: string
  image_cta_url?: string
  image_cta_url_target?: string
  cta_title?: string
  cta_primary: boolean
  cta_url?: string
  cta_url_target?: string
  background_color: CampaignBannerStoryblok['background_color'][0]['background_color']
  desktop_vertical_image_alignment?: CampaignBannerStoryblok['desktop_vertical_image_alignment']
  desktop_horizontal_image_alignment?: CampaignBannerStoryblok['desktop_horizontal_image_alignment']
  mobile_vertical_image_alignment?: CampaignBannerStoryblok['mobile_vertical_image_alignment']
}

export const CampaignBannerBlock = ({
  title,
  text,
  image_url,
  image_alt,
  image_cta_url,
  image_cta_url_target,
  cta_title,
  cta_primary,
  cta_url,
  cta_url_target,
  background_color,
  desktop_vertical_image_alignment,
  desktop_horizontal_image_alignment,
  mobile_vertical_image_alignment,
}: CampaignBannerProps) => {
  const {width, height} = getImageResolution(image_url ?? '')

  const imageContainerClassNames = classNames(
    'hidden lg:block absolute h-[125%] w-1/2',
    {
      'bottom-0 left-4':
        desktop_vertical_image_alignment === 'bottom' &&
        desktop_horizontal_image_alignment === 'left',
      'top-0 left-4':
        desktop_vertical_image_alignment === 'top' &&
        desktop_horizontal_image_alignment === 'left',
      'bottom-0 right-4':
        desktop_vertical_image_alignment === 'bottom' &&
        desktop_horizontal_image_alignment === 'right',
      'top-0 right-4':
        desktop_vertical_image_alignment === 'top' &&
        desktop_horizontal_image_alignment === 'right',
      'left-4':
        desktop_vertical_image_alignment === 'center' &&
        desktop_horizontal_image_alignment == 'left',
      'right-4':
        desktop_vertical_image_alignment === 'center' &&
        desktop_horizontal_image_alignment === 'right',
    },
  )

  const imageClassNames = classNames('object-contain', {
    'object-bottom': desktop_vertical_image_alignment === 'bottom',
    'object-top': desktop_vertical_image_alignment === 'top',
  })

  const rootContainer = classNames(
    'h-auto lg:flex lg:min-h-[315px] lg:items-center lg:justify-end',
    {
      'lg:flex-row': desktop_horizontal_image_alignment === 'left',
      'lg:flex-row-reverse': desktop_horizontal_image_alignment === 'right',
    },
  )

  const mobileRootContainer = classNames(
    `relative flex flex-col rounded-2xl px-8 lg:hidden ${BackgroundMapper(
      background_color,
    )}`,
    {
      'pb-0 pt-8': mobile_vertical_image_alignment === 'bottom',
      'py-8': mobile_vertical_image_alignment !== 'bottom',
    },
  )

  const mobileBackgroundContainer = classNames(
    `absolute inset-x-0 bottom-0 -z-10 rounded-2xl ${BackgroundMapper(
      background_color,
    )}`,
    {
      'top-[20%]': mobile_vertical_image_alignment === 'top',
      'top-0': mobile_vertical_image_alignment !== 'top',
    },
  )

  return (
    <>
      <div
        className={`hidden flex-col rounded-2xl px-8 md:px-16 lg:relative lg:flex ${BackgroundMapper(
          background_color,
        )}`}
      >
        <div className={rootContainer}>
          <div className="flex w-full flex-col justify-around space-y-5 py-8 lg:w-2/5">
            <div className="font-rubik text-[32px] font-bold leading-[38px] text-dats-s5">
              {title}
            </div>
            <div className="font-body text-base font-semibold leading-6 text-dats-s5">
              <TextBlock innerHtml={text} className="pb-0" />
            </div>
            {cta_title && cta_url ? (
              <ButtonBlock
                title={cta_title}
                type="button"
                styling={cta_primary ? 'primary' : 'secondary'}
                className="mt-0"
                cta_url={cta_url}
                cta_target={cta_url_target}
              />
            ) : null}
          </div>

          <div className={imageContainerClassNames}>
            <div className="flex items-center justify-center">
              {image_url ? (
                image_cta_url ? (
                  <Link href={image_cta_url} target={image_cta_url_target}>
                    <Image
                      src={image_url}
                      alt={image_alt ?? ''}
                      fill={true}
                      className={imageClassNames}
                    />
                  </Link>
                ) : (
                  <Image
                    src={image_url}
                    alt={image_alt ?? ''}
                    fill={true}
                    className={imageClassNames}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: `${
            mobile_vertical_image_alignment !== 'top'
              ? BackgroundMapper(background_color)
              : ''
          }`,
        }}
        className={mobileRootContainer}
      >
        <div className="flex w-full flex-col justify-around space-y-[25px]">
          {mobile_vertical_image_alignment === 'top' ? (
            <div className="mb-[15px] flex items-center justify-center">
              {image_url ? (
                image_cta_url ? (
                  <Link href={image_cta_url} target={image_cta_url_target}>
                    <Image
                      src={image_url}
                      alt={image_alt ?? ''}
                      width={width}
                      height={height}
                      fill={false}
                      className={`${imageClassNames}`}
                    />
                  </Link>
                ) : (
                  <Image
                    src={image_url}
                    alt={image_alt ?? ''}
                    width={width}
                    height={height}
                    fill={false}
                    className={`${imageClassNames}`}
                  />
                )
              ) : null}
            </div>
          ) : null}
          <div className="font-rubik text-[32px] font-bold leading-[38px] text-dats-s5">
            {title}
          </div>
          <div className="font-body text-base font-semibold leading-6 text-dats-s5">
            <TextBlock innerHtml={text} className="pb-0" />
          </div>
          {cta_title && cta_url ? (
            <ButtonBlock
              title={cta_title}
              type="button"
              styling={cta_primary ? 'primary' : 'secondary'}
              className="mt-0 w-full"
              cta_url={cta_url}
              cta_target={cta_url_target}
            />
          ) : null}

          {mobile_vertical_image_alignment === 'bottom' ? (
            <div className="!mb-[25px] !mt-10 flex items-center justify-center">
              {image_url ? (
                image_cta_url ? (
                  <Link href={image_cta_url} target={image_cta_url_target}>
                    <Image
                      src={image_url}
                      alt={image_alt ?? ''}
                      width={width}
                      height={height}
                      fill={false}
                      className={`${imageClassNames}`}
                    />
                  </Link>
                ) : (
                  <Image
                    src={image_url}
                    alt={image_alt ?? ''}
                    width={width}
                    height={height}
                    fill={false}
                    className={`${imageClassNames}`}
                  />
                )
              ) : null}
            </div>
          ) : null}

          {/* Absolute positioned rounded background with top margin */}
          {mobile_vertical_image_alignment === 'top' ? (
            <div className={mobileBackgroundContainer}></div>
          ) : null}
        </div>
      </div>
    </>
  )
}

type CampaignBannerContainerProps = {
  blok: CampaignBannerStoryblok
}
const CampaignBannerContainer = ({blok}: CampaignBannerContainerProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <CampaignBannerBlock
        title={blok.title}
        text={renderRichText(blok.text) ?? ''}
        image_url={blok.image?.filename}
        image_alt={blok.image?.alt}
        image_cta_url={blok.image_url?.cached_url ?? blok.image_url?.url}
        image_cta_url_target={blok.image_url?.target}
        cta_title={blok.cta_title}
        cta_primary={blok.cta_primary ?? false}
        cta_url={linkTypeChecker(blok.cta_url)}
        cta_url_target={blok.cta_url?.target}
        background_color={
          blok.background_color.length > 0
            ? blok.background_color[0]['background_color']
            : 'white'
        }
        desktop_vertical_image_alignment={
          blok.desktop_vertical_image_alignment ?? 'center'
        }
        desktop_horizontal_image_alignment={
          blok.desktop_horizontal_image_alignment ?? 'left'
        }
        mobile_vertical_image_alignment={
          blok.mobile_vertical_image_alignment ?? 'top'
        }
      />
    </div>
  )
}
export default CampaignBannerContainer
